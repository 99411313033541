<template>
    <div class="row mb-5 pb-5">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div class="card course-card mw-100 mh-auto p-0 shadow-xss border-0 rounded-lg overflow-hidden px-3">
                <div class="card-body p-0">
                    <div class="row justify-content-center pt-1">
                        <div class="col-12 rounded-xxl bg-white px-0">
                            <div class="modal-popup-header w-100">
                                <div class="card p-3 d-block border-0 d-block">
                                    <div class="row">
                                        <div class="col-8">
                                            <h5 class="fw-700 text-primary font-xss mt-2 mb-1">E-Space</h5>
                                            <h4 class="text-grey-500 font-xssss mt-0"><span class="d-inline-block bg-success btn-round-xss m-0"></span> Online</h4>
                                        </div>
                                        <div class="col-4" v-if="!isLoad && user && user.role_id != 2">
                                            <div class="row">
                                                <div class="col-10">
                                                    <div class="form-group">
                                                        <select class="form-control" v-model="program" @change="changeProgram">
                                                            <option value="">All</option>
                                                            <option value="SHINE">Shine</option>
                                                            <option value="GLORY">Glory</option>
                                                            <option value="TRIUMPH">Triumph</option>
                                                            <option value="EXCLUSIVE">Exclusive</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-2">
                                                    <a href="javascript:void(0)" @click="clearChat()" class="btn btn-lg btn-danger float-right"><i class="fas fa-trash"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-4" v-else>
                                            <div class="form-group">
                                                <select class="form-control" v-model="program" @change="changeProgram">
                                                    <option value="">All</option>
                                                    <option value="SHINE">Shine</option>
                                                    <option value="GLORY">Glory</option>
                                                    <option value="TRIUMPH">Triumph</option>
                                                    <option value="EXCLUSIVE">Exclusive</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="chat-body" v-if="!isLoad">
                                <div class="messages-content py-3 px-3 scroll-bar" id="chat-wrapper">
                                    <a v-if="messages.more" href="javascript:void(0)" @click="loadMessages()" class="w-100 text-center text-current">Load Message</a>
                                    <div class="message-item" v-for="(item, i) in messages.data.slice().reverse()" :key="i" :class="{'outgoing-message' : item.user_id.id == user.id}">
                                        <div class="message-user">
                                            <figure class="avatar">
                                                <img :src="item.user_id.photo ?  item.user_id.photo : '/images/profile.png'" alt="image">
                                            </figure>
                                            <div>
                                                <h5>{{item.user_id.name}}</h5>
                                                <div class="time">{{item.created_at | datetime}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="message-wrap">{{item.message}}</div>
                                        <figure v-if="item.type == 'image' && item.path" class="mt-2" style="max-width: 300px; min-width: 200px;">
                                            <img :src=" item.path" class="w-100 rounded-lg" alt="">
                                        </figure>
                                        <div v-if="item.type == 'audio' && item.path" class="mt-2" style="max-width: 300px; min-width: 200px;">
                                            <audio controls  class="w-100">
                                                <source :src=" item.path" type="audio/ogg">
                                                <source :src=" item.path" type="audio/mpeg">
                                            </audio>
                                        </div>
                                        <div v-if="item.type == 'video' && item.path" class="mt-2" style="max-width: 300px; min-width: 200px;">
                                            <video controls  class="w-100">
                                                <source :src=" item.path" type="video/mp4">
                                                <source :src=" item.path" type="video/ogg">
                                            </video>
                                        </div>
                                        <a v-if="item.type == 'file' && item.path" target="_blank" :href=" item.path" class="btn btn-info mt-2">
                                            <i class="fas fa-paperclip mr-2"></i> File
                                        </a>
                                    </div>
                                </div>
                                <div class="chat-bottom dark-bg p-3 shadow-xss w-100">
                                    <div>
                                        <a v-if="(!$refs.upload || !$refs.upload.active) && path" target="_blank" :href=" path" class="btn btn-sm btn-current rounded-lg font-xsssss"><i class="fas fa-paperclip text-white mr-2 rounded-lg mr-2"></i>Preview</a>
                                        <a v-if="!$refs.upload || $refs.upload.active" @click.prevent="$refs.upload.active = false" href="javascript:void(0)" class="btn btn-sm bg-grey rounded-lg font-xssss mr-2"><i class="fas fa-times text-loader mr-2"></i>Uploading...</a>
                                        <a v-if="!$refs.upload || $refs.upload.active" @click.prevent="$refs.upload.active = false" href="javascript:void(0)" class="btn btn-sm btn-danger rounded-circle font-xsssss"><i class="fas fa-times text-white"></i></a>
                                    </div>
                                    <form class="chat-form" @submit.prevent="sendMessage()">
                                        <file-upload
                                            name="upload"
                                            class="btn bg-current float-left rounded-circle"
                                            ref="upload"
                                            @input="updateFile"
                                            @input-file="inputFile"
                                            :custom-action="fileAction"
                                            >
                                            <i class="fas fa-paperclip text-white"></i>
                                        </file-upload>
                                        <input v-model="input" :disabled="load" class="form-group bg-lightgrey text-black" type="text" :placeholder="load ? 'Sending...' : 'Start typing..'" required>
                                        <button class="bg-current" :disabled="load"><i class="ti-arrow-right text-white"></i></button>
                                    </form>
                                </div>
                            </div>
                            <div class="row min-vh-75" v-else>
                                <div class="col-12 py-3 text-center">
                                    <div class="my-3">
                                        <div class="spinner-grow text-warning mx-3" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                        <div class="spinner-grow text-warning mx-3" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                        <div class="spinner-grow text-warning mx-3" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                        <div class="spinner-grow text-warning mx-3" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                        <div class="spinner-grow text-warning mx-3" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                        <div class="spinner-grow text-warning mx-3" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import FileUpload from 'vue-upload-component'

export default ({
    name: 'MeetTutors',
    components: {
        FileUpload,
    },
    data(){
        return{
            media: process.env.VUE_APP_URL_CLOUD,
            files: [],
            user: {},
            isLoad: true,
            load: true,
            messages:{
                data: {},
                more: false,
            },
            page: 1,
            input: '',
            type: 'text',
            path: '',
            lastId: '',
            program: '',
            course_id: '',
        }
    },
    filters:{
        datetime: function (date) {
            return moment(date).calendar();
        }
    },
    created(){
        this.getAccount()
    },
    mounted(){
        this.getMessages()
    },
    methods: {
        clearChat() {
            this.$swal({
                title: 'Are you sure?',
                text: 'You can\'t revert your action',
                showCancelButton: true,
                confirmButtonText: 'Yes Delete it!',
                cancelButtonText: 'No, Keep it!',
                showCloseButton: true,
                showLoaderOnConfirm: true
                }).then(async (result) => {
                if(result.value) {
                    var data = {
                        _method: 'delete',
                        course_id: this.course_id,
                        program: this.program,
                    }
                    await axios.post(`${process.env.VUE_APP_URL_API}/admin/live-chat/clear`, data, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }).then(res => {
                        this.$swal({
                            toast: true,
                            title: 'E-Space',
                            text: res.data.message,
                            icon: 'success',
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                        })
                        this.getMessages()
                    }).catch(err => {
                        if (err.response.status == 422) {
                            this.$swal({
                                toast: true,
                                title: 'E-Space',
                                text: err.response.data.message[0],
                                icon: 'error',
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 3000,
                                timerProgressBar: true,
                            })
                        }
                    })
                } else {
                    this.$swal({
                        toast: true,
                        title: 'E-Space',
                        text: 'Your data is still intact',
                        icon: 'info',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
        changeProgram() {
            this.files = []
            this.isLoad = true
            this.load = true
            this.messages = {
                data: {},
                more: false
            }
            this.page = 1
            this.input = ''
            this.type = 'text'
            this.path = ''
            this.lastId = ''
            this.course_id = ''
            this.getMessages()
        },
        async getAccount() {
            await axios.get(`${process.env.VUE_APP_URL_API}/user`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.user = res.data
            })
        },
        async getMessages(){
            await axios.get(`${process.env.VUE_APP_URL_API}/live-chat?page=${this.page}&lastid=${this.lastId}&program=${this.program}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.token}`
                }
            }).then(res => {
                let length = res.data.message.length;
                if(length > 0) {
                    let lastId = res.data.message[length-1].id
                    this.lastId = lastId;
                }
                this.messages.data = [...res.data.message]
                this.messages.more = res.data.more
                this.isLoad = false
                this.load = false
                this.scrollToEnd()
            }).catch((err) => console.error(err))
        },
        async loadMessages(){
            this.page += 1;
            await axios.get(`${process.env.VUE_APP_URL_API}/live-chat?page=${this.page}&lastid=${this.lastId}&program=${this.program}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.token}`
                }
            }).then(res => {
                let length = res.data.message.length;
                if(length > 0) {
                    let lastId = res.data.message[length-1].id
                    this.lastId = lastId;
                }
                res.data.message.map((el) => {
                    this.messages.data.push(el)
                })
                this.messages.more = res.data.more
            }).catch((err) => console.error(err))
        },
        async sendMessage(){
            this.load = true
            var data = {
                message: this.input,
                path: this.path,
                type: this.type,
                program: this.program,
                course_id: this.course_id
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/live-chat/send`, data, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(() => {
                this.input = ''
                this.path = ''
                this.type = 'text'
                this.load = false
            }).catch(() => {
                this.input = ''
                this.path = ''
                this.type = 'text'
                this.load = false
            })
        },
        scrollToEnd() {
            let container = this.$el.querySelector("#chat-wrapper");
            if(container) {
                setTimeout(function () {
                    container.scrollTop = container.scrollHeight;
                }, 500);
            }
        },
        pushMessage(data) {
            var datas = {
                created_at: data.created_at,
                message: data.message,
                path: data.path,
                type: data.type,
                program: data.program,
                user_id: {
                    id: data.user_id,
                    name: data.name,
                    photo: data.photo
                }
            }
            this.messages.data.unshift(datas)
            this.scrollToEnd()
        },
        updateFile(value) {
            this.files = value
        },
        async fileAction(file) {
            return this.postFile(file)
        },
        async inputFile(newFile, oldFile) {
            if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
                if (!this.$refs.upload.active) {
                    this.$refs.upload.active = true
                }
            }
        },
        async postFile(file){
            let data = new FormData();
            data.append('dir', 'meet-tutors');
            data.append('attachment', file.file);
            data.append('_token', 'elingwaysecretkey');
            await axios.post(`${process.env.VUE_APP_URL_MEDIA}/api/storage/store-cloud`, data, {withCredentials: false}, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'multipart/form-data',
                },
            }).then(res => {
                let filetype = file.file.type;
                if(filetype.includes('image')) {
                    this.type = 'image'
                } else if(filetype.includes('audio')) {
                    this.type = 'audio'
                } else if(filetype.includes('video')) {
                    this.type = 'video'
                } else {
                    this.type = 'file'
                }
                file.response = res.data
                this.path = res.data.path
                return res.data
            }).catch(err => {
                console.log(err.response)
            })
        },
    }
})
</script>
<style scoped>
::v-deep .btn label{
    cursor: pointer;
}
.messages-content {
    height: 50vh;
    margin-bottom: 5rem !important;
}
@media (max-width: 992px) { 
    .chat-body {
        overflow: hidden !important;
    }
    .messages-content {
        margin-bottom: -2rem !important;
        padding-bottom: 7rem !important;
        padding-top: 1rem !important;
    }
    
}
</style>